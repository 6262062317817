<template>
  <v-container>
    <v-card class="elevation-1">
      <v-card-title>
        <span class="headline"
          >Biometric Device Registration (Smartphone)</span
        >
      </v-card-title>
      <v-card-subtitle>If a laptop or desktop has biometrics, register it here</v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="3">
            <b>Signed-In User:</b>
          </v-col>
          <v-col cols="9">
            {{ authUser }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <b>Biometric Status:</b>
          </v-col>
          <v-col cols="9">
            {{ status }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="activeRegistrations"
              :headers="registrationHeaders"
              dense
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:[`item`]="{ item, headers }">
                <CRUDRow
                  :canEdit="false"
                  :canDelete="false"
                  :item="item"
                  :headers="headers"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="blue darken-1" @click="register">
          Register this device
        </v-btn>
        <v-btn text color="blue darken-1" @click="removeAll">
          Delete all registrations
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { clients } from '../../util/clients';
import { getChallengeByUsername, derivePubSerSideObjs } from './ReAuth.util';

import CRUDRow from './base/crud/CRUDRow.vue';
import {
  arrayToBase64Str,
  base64StrToArray,
} from '../../util/authorization';

const getPublicKeyOptions = (username, challengeArray) => {
  const relyingPartyId = window.location.hostname;
  // console.info(base64js.fromByteArray(challengeArray)); // match this...
  const publicKeyCredentialCreationOptions = {
    challenge: challengeArray,
    rp: {
      name: 'TM Consulting Local System',
      id: relyingPartyId,
    },
    user: {
      // btoa() ensures that the username is a base64 encodable string
      // we are just setting it to something that identifies the user
      // opaquely to the WebAuthN encryption system
      id: base64StrToArray(btoa(username)),
      name: username,
      displayName: username,
    },
    pubKeyCredParams: [{ alg: -7, type: 'public-key' }],
    timeout: 60000,
    attestation: 'direct',
  };
  return publicKeyCredentialCreationOptions;
};

export default {
  name: 'UserRegWebAuthNLocal',
  components: {
    CRUDRow,
  },
  data: () => ({
    credentialIdStr: '',
    status: '...',
    registrations: [],
    registrationHeaders: [
      { text: 'Active?', value: 'isActive' },
      { text: 'Credential Id String', value: 'credentialIdStr' },
      { text: 'Created On', value: 'createdOn', formatter: 'localDateTime' },
      { text: 'Public Key', value: 'publicKeyByteStr' },
    ],
  }),
  computed: {
    ...mapGetters('auth/token', ['authUser']),
    activeRegistrations() {
      return this.registrations.map((r) => {
        const isActive = r.credentialIdStr === this.credentialIdStr ? '*' : '';
        return {
          ...r,
          isActive,
        };
      });
    },
  },
  mounted() {
    this.loadExistingRegistrations();
  },
  methods: {
    ...mapMutations(['appendRecentLog']),
    ...mapActions(['flashInfo', 'flashError']),
    debugLog(what, infoStr) {
      const msg = (infoStr || '').toString();
      this.appendRecentLog(['UserRegWebAuthN', `${what}: ${msg}`]);
    },
    async loadExistingRegistrations() {
      this.credentialIdStr = localStorage.getItem('webAuthN_credentialId') || '';
      const resp = await clients.auth.getWebAuthNRegStatus(this.authUser || '');
      if (resp && resp.data) {
        this.status = resp.data.status || '';
        this.registrations = resp.data.webAuthN || [];
      }
    },
    async removeAll() {
      if (
        !window.confirm(
          'On your next sign-in, you will only be able to use your existing password. Continue?',
        )
      ) {
        this.flashInfo('User cancelled');
        return;
      }
      await clients.auth.clearWebAuthNPubKeyChallenge(this.authUser);
      this.flashInfo('Deleted all registered devices for this user.');
      this.loadExistingRegistrations();
    },
    async register() {
      if (!window.confirm('Please only do this once per device. Continue?')) {
        this.flashInfo('User cancelled');
        return;
      }
      const username = this.authUser;

      this.debugLog('username', username);
      let credential;
      try {
        const { challengeArray } = await getChallengeByUsername(username);
        this.debugLog('challengeArray', challengeArray);
        if (!challengeArray) {
          this.flashError(
            'Unable to get a challenge from the server. Try again later.',
          );
        }
        const publicKeyOptions = getPublicKeyOptions(username, challengeArray);
        this.debugLog('publicKeyOptions', JSON.stringify(publicKeyOptions));

        credential = await navigator.credentials.create({
          publicKey: publicKeyOptions,
        });
        if (credential) {
          this.debugLog('typeof credential', (typeof credential).toString());
          this.debugLog('credential', JSON.stringify(credential));
          if (credential.id) this.debugLog('id', credential.id);
          if (credential.rawId) this.debugLog('rawId', credential.rawId);
          if (credential.type) this.debugLog('type', credential.type);
          if (credential.authenticatorAttachment) {
            this.debugLog('authAttach', credential.authenticatorAttachment);
          }
          if (credential.response) {
            if (credential.response.attestationObject) {
              const str = arrayToBase64Str(credential.response.attestationObject);
              this.debugLog('attObj', str);
            }
            if (credential.response.clientDataJSON) {
              const str = arrayToBase64Str(credential.response.clientDataJSON);
              this.debugLog('clientDataJSON', str);
            }
          }
        }
      } catch (nae) {
        const msg = 'Your authentication was not allowed for some reason.';
        this.flashError(msg);
        console.error('msg', nae);
        this.debugLog('nae-json-str', JSON.stringify(nae));
        this.debugLog('nae-keys', Object.keys(nae));
        this.debugLog('nae-toStr', nae.toString());
        this.debugLog('nae-message', nae.message);
      }

      if (
        !(
          credential
          && credential.response
          && credential.response.clientDataJSON
        )
      ) {
        this.flashError(
          'Unable to decode the public key and complete registration.',
        );
        return;
      }

      const {
        credentialIdStr,
        publicKeyByteStr,
        challengeStr,
      } = await derivePubSerSideObjs(credential);

      const finalRegResult = await clients.auth.setWebAuthNPubKeyChallenge(
        username,
        {
          credentialIdStr,
          publicKeyByteStr,
          challengeStr,
        },
      );
      if (
        finalRegResult
        && finalRegResult.data
        && finalRegResult.data.registered === true
      ) {
        localStorage.setItem('webAuthN_credentialId', credentialIdStr);
        this.flashInfo('Successfully registered!');
        this.loadExistingRegistrations();
      } else {
        this.flashError('Registration validation failed on the server-side');
      }
    },
  },
};
</script>
