<template>
  <v-container>
    <v-tabs v-model="activetab">
      <v-tab>
        <span>Biometric</span>
      </v-tab>
      <v-tab>
        <span>Remote</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activetab">
      <v-tab-item>
        <UserRegWebAuthNLocal />
      </v-tab-item>
      <v-tab-item>
        <UserRegWebAuthNRemote />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>

import UserRegWebAuthNLocal from './UserRegWebAuthNLocal.vue';
import UserRegWebAuthNRemote from './UserRegWebAuthNRemote.vue';

import { checkWebAuthN } from './ReAuth.util';

export default {
  name: 'UserRegWebAuthN',
  components: {
    UserRegWebAuthNLocal,
    UserRegWebAuthNRemote,
  },
  data: () => ({
    activetab: 0,
  }),
  async mounted() {
    const canWebAuthN = await checkWebAuthN();
    this.activetab = canWebAuthN ? 0 : 1;
  },
};
</script>
