<template>
  <v-container>
    <v-card class="elevation-1">
      <v-card-title>
        <span class="headline">Desktop/Laptop Registration</span>
      </v-card-title>
      <v-card-subtitle>If your desktop or laptop has a thumbprint reader,
        register it under the <i>Biometric</i> tab</v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="3">
            <b>Signed-In User:</b>
          </v-col>
          <v-col cols="9">
            {{ authUser }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <b>Device Otp:</b>
          </v-col>
          <v-col cols="9">
            {{ deviceOtp }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="remoteAuthsComputed"
              :headers="remoteAuthHeaders"
              dense
              class="elevation-1"
              hide-default-footer
            >
              <template v-slot:[`item`]="{ item, headers }">
                <CRUDRow
                  :canEdit="false"
                  :canDelete="false"
                  :item="item"
                  :headers="headers"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="blue darken-1" @click="register">
          Register this device
        </v-btn>
        <v-btn text color="blue darken-1" @click="removeAll">
          Delete all registrations
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { clients } from '../../util/clients';
import CRUDRow from './base/crud/CRUDRow.vue';

const { auth } = clients.direct;

export default {
  name: 'UserRegWebAuthNRemote',
  components: {
    CRUDRow,
  },
  data: () => ({
    status: '...',
    refreshCounter: 0,
    remoteAuths: [],
    remoteAuthHeaders: [
      { text: 'This Device?', value: 'isDeviceOtpMatching' },
      { text: 'User-Device Otp', value: 'userDeviceOtp' },
      { text: 'Last Issued', value: 'issued', formatter: 'localDateTime' },
      { text: 'Descriptor (of device-browser)', value: 'descriptor' },
    ],
  }),
  computed: {
    ...mapGetters('auth/token', ['authUser']),
    deviceOtp: {
      get: function get() {
        const key = this.refreshCounter ? 'deviceOtp' : 'deviceOtp';
        return localStorage.getItem(key) || '';
      },
      set: function set(uuid) {
        this.refreshCounter += 1;
        localStorage.setItem('deviceOtp', uuid || '');
      },
    },
    remoteAuthsComputed() {
      return (this.remoteAuths || []).map((ra) => {
        const userDeviceOtp = (ra.userDeviceOtp || '');
        const endsWithDeviceOtp = userDeviceOtp !== ''
          && userDeviceOtp.match(new RegExp(`-${this.deviceOtp}$`));
        return {
          ...ra,
          isDeviceOtpMatching: endsWithDeviceOtp != null ? '*' : '',
        };
      });
    },
  },
  mounted() {
    this.loadExistingRemoteAuths();
  },
  methods: {
    ...mapMutations([
      'appendRecentLog',
    ]),
    ...mapActions([
      'flashInfo',
      'flashError',
    ]),
    async loadExistingRemoteAuths() {
      try {
        const resp = await clients.auth.getRemoteAuths(this.authUser || '');
        if (resp && resp.data) {
          this.remoteAuths = resp.data.remoteauths || [];
        } else {
          this.remoteAuths = [];
        }
      } catch (err) {
        this.flashError('Unable to load remote auths.');
        this.appendRecentLog(['err-message', err ? err.message : '']);
        console.error('msg', err);
      }
    },
    async removeAll() {
      if (
        !window.confirm(
          'This will clear all remote authentication devices associated with your username. Continue?',
        )
      ) {
        this.flashInfo('User cancelled');
        return;
      }

      await auth.mutate({
        mutation: gql`mutation clearRemoteAuth ($username: String)  {
          clearRemoteAuth(username: $username)
        }`,
        variables: {
          username: this.authUser,
        },
      });
      this.deviceOtp = '';
      this.remoteAuths = [];
      this.flashInfo('Deleted all remote authentication devices associated with your username.');
      this.$nextTick(() => this.loadExistingRemoteAuths());
    },
    async register() {
      if (!window.confirm('Please only do this once per device. Continue?')) {
        this.flashInfo('User cancelled');
        return;
      }
      try {
        const uuid = this.deviceOtp || crypto.randomUUID();
        const result = await auth.mutate({
          mutation: gql`mutation registerRemoteAuth (
              $username: String, $deviceOtp: String, $descriptor: String
            ) {
              registerRemoteAuth(
                username: $username, deviceOtp: $deviceOtp, descriptor: $descriptor
              )
          }`,
          variables: {
            username: this.authUser,
            deviceOtp: uuid,
            descriptor: navigator.userAgent,
          },
        });
        if (result && result.data) {
          console.info(result.data);
          this.deviceOtp = uuid || '';
          this.flashInfo('Successfully registered!');
          this.$nextTick(() => this.loadExistingRemoteAuths());
        } else {
          this.flashError('Remote auth registration failed for some reason.');
        }
      } catch (err) {
        const msg = 'Your registration was not allowed for some reason.';
        this.flashError(msg);
        console.error('msg', err);
        this.appendRecentLog(['err-message', err ? err.message : '']);
      }
    },
  },
};
</script>
